body {
    background-image: linear-gradient(to bottom right, rgba(252,252,252), rgba(224,231,226), rgba(221,228,253));
    background-attachment: fixed;
}

/* .font-link {
    font-family: 'Martian Mono', monospace;
    font-family: 'PT Sans', sans-serif;
} */

#split {
    display: flex;
    justify-content: center;
    padding-top: 2%;
}

@font-face {
    font-family: PTSans;
    src: url(../PTSans-Regular.ttf);
}

* {
    font-family: PTSans;
}

/* About */
#about {
    display: flex;
    justify-content: space-evenly;
    
    
    letter-spacing: 2px;
    padding-top: 2%;
}



#textblock {
    width: 40%;
    background-image: linear-gradient(to bottom right, rgba(253,243,234, .5), rgba(255,240,244, .5), rgba(255,239,249, .5));
    padding: 2%;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.2);

}

#headshotblock {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 400px;
    max-height: 555px;
    background-image: linear-gradient(to bottom right, rgba(253,243,234, .5), rgba(255,240,244, .5), rgba(255,239,249, .5));
    padding: 2%;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.2);
}

.linklogo {
    height: 25px;
    margin-right: 10px;
}

.headshotlink {
    text-decoration: none;
    color: black;
    background: -webkit-linear-gradient(
        rgba(255, 0, 0, 1),
        rgba(255, 0, 180, 1),
        rgba(0, 100, 200, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
}

.linkblock {
    display: flex;
    align-items: center;
}

#headshot {
    display: block;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    height: auto;
    border-radius: 20px;
}

/* #aboutintro {

} */

#downloadbutton {
    margin-top: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: black;
    color: white;
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 20px;
}


@media (max-width: 815px) {
    #about {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        letter-spacing: 2px;
        padding-top: 2%;
        
    }

    #textblock {
        width: 90%;
        max-width: none; 
        margin-top: 30px;
        margin-bottom: 30px;
    }

    #headshotblock {
        width: 350px;
         
        max-width: none; 
        padding-bottom: 30px;
    }
}
/* End About */


/* Inspiration */
#POD {
    max-width: 85%;
    margin-bottom: 15%;
    background-image: linear-gradient(to bottom right, rgba(253,243,234, .5), rgba(255,240,244, .5), rgba(255,239,249, .5));
    
    padding: 2%;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.2);
}

#nasapic {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    min-height: 500px;
}

#inspotext {
    text-align: center;
    letter-spacing: 1.5px
}

/* End Inspiration */

/* Projects */
.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 2px;
}

#projectintro {
    max-width: 75%;
    text-align: center;
}

.projectblocks {
    max-width: 85%;
    margin-bottom: 3%;
    background-image: linear-gradient(to bottom right, rgba(255,239,249), rgba(255,240,244), rgba(253,243,234));
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 25px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.2);
}

/* .projectblocks:hover {
    background-image: linear-gradient(to bottom right, rgba(255,239,249), rgba(255,240,244), rgba(253,243,234));
} */

.projectlinks {
    text-decoration: none;
    color: black;
    background: -webkit-linear-gradient(
        rgba(255, 0, 0, 1),
        rgba(255, 0, 180, 1),
        rgba(0, 100, 200, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}

.innerblock {
    display: flex;
    align-items: center;
}

.projectimages {
    display: block;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    
}

/* .projecttextblock {
 
} */

/* End Projects */